import React from "react";
import { PortableText } from "@portabletext/react";
import ContentLink from "./textcontent/ContentLink";

const parameterize = (content) => {
  return content[0].toLowerCase().replace(/[^a-z0-9]+/g, "-");
};

// This is functionally similar to TextContentRenderer's components config but does not accept tables
// This is intentionally different as it is unclear how these two might evolve over time
const components = {
  block: {
    h1: ({ children }) => (
      <h3 className="article-subheading" id={parameterize(children)}>
        {children}
      </h3>
    ),
    h2: ({ children }) => <h4 id={parameterize(children)}>{children}</h4>,
    h3: ({ children }) => <h5 id={parameterize(children)}>{children}</h5>,
    h4: ({ children }) => <h6 id={parameterize(children)}>{children}</h6>,
    normal: ({ children }) => <>{children}</>,
  },
  marks: {
    contentLink: ({ value, children }) => (
      <ContentLink contentId={value.content._ref} heading={value.heading}>
        {children}
      </ContentLink>
    ),
    definitionLink: ({ value, children }) => {
      return (
        <button
          className="definition-term__link"
          data-definition-id={value.definition._ref}
        >
          {children}
        </button>
      );
    },
  },
};

const TextContentTable = (props) => {
  const { rows } = props;
  return (
    <div className="statistic-section">
      <div className="statistic">
        <table>
          <tbody>
            {rows.map((row, index) => (
              <tr key={index}>
                <th>
                  <PortableText
                    value={row.cells[0].content}
                    components={components}
                  />
                </th>

                {row.cells.slice(1).map((cell, cellIndex) => (
                  <td key={cellIndex}>
                    <PortableText
                      value={cell.content}
                      components={components}
                    />
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default TextContentTable;
