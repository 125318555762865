import React from "react";
import { PortableText } from "@portabletext/react";

const TextContentRenderer = (props) => {
  const components = {
    block: {
      normal: ({ children }) => <span>{children}</span>,
    },
    annotations: {
      link: ({ value, children }) => {
        const { href, blank } = value;
        const target = blank ? { target: "_blank", rel: "noreferrer" } : {};

        return (
          <a href={href} {...target}>
            {children}
          </a>
        );
      },
    },
  };

  return (
    <>
      <sup className="db pr1" style={{ top: "0" }}>
        {props.index}
      </sup>
      <div style={{ textIndent: "-1rem", paddingLeft: "1rem" }}>
        <PortableText value={props.value} components={components} />;
      </div>
    </>
  );
};

export default TextContentRenderer;
